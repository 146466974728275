import React from "react"
import styled from "@emotion/styled"
import { SmallContainer, PaddingContainer } from "../styled"
import Footer from "./footer"
import theme from "../theme"
import ReactHTMLParser from "react-html-parser"

const BasicPage = ( props ) => {
	return (
		<div style={{
			background:theme.colors.lightGrey,
			borderTop:'1px solid'
		}}>
			<SmallContainer>
				<ContentContainer>
					<h1>{props.title}</h1>
					<div>{ReactHTMLParser(props.content)}</div>
				</ContentContainer>
			</SmallContainer>
			<Footer />
		</div>
	)
}

export default BasicPage

const ContentContainer = styled(PaddingContainer)`
	margin:70px 0;
	h1 {
		margin-bottom:5px;
	}
	em {
		font-style:italic;
		font-size:.7rem;
		font-weight:200;
	}
	p {
		margin-top:30px;
	}
`