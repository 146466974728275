import * as React from "react"
import { Link } from "gatsby"
import Layout from "../components/layout"
import Seo from "../components/seo"
import BasicPage from "../components/basicPage"


const content = `
<em>Last updated on November 18, 2020</em>

<p>Thank you for using the Kosmique app. The terms and conditions set forth herein (the “Terms of Use”) apply to this app, including any related apps contained or linked to this app (collectively, the “app”). Please read the Terms of Use carefully before using the app.</p>
 
<h2>Your Acceptance of the Terms of Use</h2>

<p>
By using this app you are agreeing to comply with and be bound by the Terms of Use, as amended from time to time, with or without notice to you. If you do not agree to the Terms of Use, please do not use the app. Kosmique and its affiliates (“our” or “we” or “us”) reserve the right, in our sole discretion, to change, modify, add, or remove portions of the Terms of Use at any time. Therefore, we suggest that you review the Terms of Use periodically for changes. By using the app after we have posted changes to the Terms of Use, you are agreeing to accept the terms as amended.</p>
 
<h2>Your Acceptance of the Privacy Policy</h2>
<p>
By agreeing to the Terms of Use, you agree to the terms of the Privacy Policy which is expressly incorporated herein. Before using the app, please carefully review the Privacy Policy. All personal information provided to us as a result of your use of the app will be handled in accordance with the Privacy Policy. To the extent there are inconsistencies between the Terms of Use and the Privacy Policy, the Terms of Use control.</p>
 

<h2>Ownership of the App and its Content</h2>
<p>
The app, and all information, materials, and other content, including, without limitation, images, illustrations, designs, icons, photographs, video clips, and written and other materials that are part of the app are copyrights, trademarks, service marks, trade names, trade dress or other intellectual property owned, controlled, or licensed by Kosmique. The app and all such content are protected by copyright, trademark, and other laws of the United States, as well as international conventions and the laws of other countries. The presence of any content on the app does not constitute a waiver of any right in such content. You do not acquire ownership rights to any content viewed through the app. Except as otherwise provided herein, no content on the app may be copied, reproduced, republished, uploaded, downloaded, modified, posted, framed, transmitted, distributed, or sold in any form or by any means, including, but not limited to, electronic, mechanical, photocopying, recording, or otherwise, without obtaining in advance written permission from Kosmique or Kosmique’s licensors. You may lawfully access and use the app to view, copy, download, or print content on the app solely for your personal, noncommercial use, provided that you do not modify the content and that you retain any and all copyright and other proprietary notices contained in the content.</p>
 
<h2>Trademarks</h2>
<p>
Kosmique’s name and logo are trademarks (whether registered or not), service marks and/or trade dress of Kosmique, and all other trademarks, service marks, trade names, logos, product and service names, graphics and button icons appearing on the app are trademarks (whether registered or not), service marks and/or trade dress of Kosmique and/or others. Nothing contained on the app should be construed as granting, by implication, estoppel or otherwise, any license or right to use any trademark, service mark, trade dress or logo displayed on the app, which use by you without the written permission of Kosmique or such other third party who may own such trademark, service mark, trade dress or logo is strictly prohibited. Any unauthorized use of any trademark, service mark, trade dress or logo displayed on the app may violate, among other things, copyright laws, trademark laws, the laws of privacy and publicity, and communications regulations and statutes. If you download any information, materials, other content ,or software from the app, you agree that you will not copy it or remove or obscure any copyright or other proprietary notices contained in any such information, materials, other content or software.</p>
 
<h2>User Accounts</h2>
<p>
If you create an account through the app, you agree that any account you create, including your username and password, are personal to you and may not be used by anyone else. You may not use the contact information of another person, including address, telephone numbers and e-mail addresses, to create an account. You are responsible for maintaining the confidentiality of your password and are fully responsible for all activities that occur under your username and password by you or by anyone else using your username and password, whether or not authorized by you. You agree to change your password immediately if you believe your password may have been compromised or used without authorization. You also agree to immediately inform us of any apparent breaches of security such as loss, theft or unauthorized disclosure or use of your username or password by contacting us using the information provided below. </p>
 
<h2>Lawful Use of the App</h2>
<p>
You agree to use the app only for lawful purposes and in accordance with any and all applicable rules, regulations and any applicable local, state, national or other law. You further agree that your communications with or through the app will not infringe on the rights of any third party or contain defamatory, libelous, abusive, or obscene material. You agree not to upload or transmit through the app any computer viruses, trojan horses, worms, or anything else designed to interfere with, interrupt or disrupt the normal operating procedures of computer software or hardware or telecommunication equipment. Any unauthorized modification, tampering or change of any information, or any interference with the availability of or access to the app is strictly prohibited. We reserve all rights and remedies available to us.</p>
 
<h2>Children’s Privacy</h2>
<p>
This app is directed to persons who are at least thirteen (13) years of age. By accessing the app and disclosing personally identifiable information, you represent that you are at least 13 years of age. We do not knowingly collect, use and/or disclose any personally identifiable information on this app from children under the age of thirteen years. For more information regarding how to protect the privacy of your child, please view our Privacy Policy.</p>
 
<h2>Disclaimer of Warranty</h2>
<p>Kosmique and its affiliates make no representations or warranties with respect to the app or its content, or any product or service available on or promoted through the app. The app and its content (including user-generated content, if any) are provided on an “as is,” “as available” basis, without representations or warranties of any kind. To the fullest extent permitted by law, Kosmique and its affiliates, service providers and licensors disclaim any and all representations and warranties, express or implied, including, but not limited to, the implied warranties of title, merchantability, fitness for a particular purpose, non-infringement, and those arising by statute, custom, course of dealing, course of performance or in any other way, with respect to the app, its content, and any products or services available or promoted through the app. Without limiting the generality of the foregoing, Kosmique and its affiliates, service providers and licensors disclaim all representations and warranties (i) relating to the security of the app, including, but not limited to, that the app will operate securely, free of any harmful components (including viruses), and/or without interruption or error and (ii) that the content of the app is accurate, complete, or current. We do not make any representations or warranties against the possibility of deletion, misdelivery or failure to store communications, personalized settings, or other data.</p>
 
<h2>Limitation of Liability</h2>
<p>
To the fullest extent permitted by applicable law, under no circumstances, including, but not limited to, negligence, shall Kosmique, its affiliates or their officers, directors employees, agents, suppliers, licensors and service providers be liable in any respect whatsoever for any losses and expenses of whatever nature and howsoever arising including, but not limited to, any direct, indirect, general, special, punitive, incidental or consequential damages; loss of use; loss of data; loss caused by a virus; loss of income or profit; loss of or damage to property; claims of third parties; or other losses of any kind or character, that result from the use of, or the inability to use, the app and/or the information, materials and other content in or accessible through the app, even if advised of the possibility of such damages. The applicable laws of certain jurisdictions may disallow or condition certain kinds of limitations or exclusions of liability. In such a case, the limitations and exclusions set out in the terms of use shall apply to the fullest extent permitted by the laws of the applicable jurisdictions. Your statutory rights as a consumer, if any, are not affected by these provisions, and we do not seek to exclude or limit liability for fraudulent misrepresentation.</p>
 
<h2>User Generated Content</h2>
<p>
Kosmique is not responsible for the accuracy, suitability, or quality of any user-generated content. You are responsible for the content that you post.  You may not post, upload or otherwise make available:</p>
<ul>
<li>any harmful, hateful, threatening, abusive, harassing, unlawful, libelous, defamatory, obscene, vulgar, pornographic, profane, racially disparaging or indecent content, or any content that promotes or encourages any of the foregoing;</li>
<li>any content that is misleading or fraudulent;</li>
<li>any content that violates or infringes on the rights of others (for example, privacy or publicity rights, patent rights, copyrights, trademark rights or confidentiality rights);</li>
<li>any image, name, or likeness of anyone other than yourself, unless (i) that person is at least eighteen years old, and you have first obtained his/her express permission or (ii) that person is under eighteen years old, but you are his/her parent or legal guardian;</li>
<li>any request for or solicitation of any personal or private information;</li>
<li>any request for or solicitation of money, goods, or services for private gain;</li>
<li>any content that contains viruses or any other material designed to interrupt, destroy or limit the functionality of any computer</li><li>software or hardware or telecommunications equipment; or</li>
<li>any content that contains advertising, promotions or marketing, or which otherwise has a commercial purpose.</li>
</ul>
<p>
You are solely responsible for and can be held personally liable for comments that are defamatory, obscene, or libelous, or that violate the Terms of Use, an obligation of confidentiality, or the rights of others.  At the time you post any content, you represent to Kosmique that such content does not violate or infringe on any rights of others (including, but not limited to, privacy or publicity rights, patent rights, copyrights, trademark rights or confidentiality rights) and does not contain defamatory or unlawful material. By using the app, you hereby grant to Kosmique and its affiliates, successors and assigns, a nonexclusive, fully paid, worldwide, perpetual, irrevocable, royalty-free, transferable license (with the right to sublicense) to use, copy, modify, distribute, publicly display and perform, publish, transmit, remove, retain repurpose and commercialize any content you post. Kosmique reserves the right to remove or block any user-generated content at any time without notice in our sole discretion, and to disclose any user-generated content and the identity of the user who posted it in response to a subpoena or whenever we believe that disclosure is appropriate to comply with the law or a court order, to prevent or investigate a possible crime or other violation of law, to protect the rights of Kosmique or others, or to enforce the Terms of Use.</p>
 
<h2>Links from and to Third-Party Apps or Websites</h2>
<p>The app may be linked from, any may provide links to, other apps and websites operated by third parties. Any links from or to other apps/websites are provided merely for the convenience of the users of the app and the inclusion of these links does not imply an endorsement, representation, or warranty by Kosmique with respect to any such linked apps or the content, products or services contained or accessible through, or the operators of, such apps. Kosmiquedoes not review or monitor any apps that are linked to the app and is not responsible for the content of any such linked apps. Your linking from or to such apps is at your sole risk. Accordingly, Kosmique shall not be responsible or liable, directly, or indirectly, for any damage or loss caused or alleged to be caused by or in connection with the use of or reliance on any content, advertising, services, products, or other materials on or available from such apps. The Terms of Use do not apply to your use of third-party apps, and your use of such apps is subject to the terms and policies of the owner of such apps. In addition, you agree not to link any of your apps or any third-party app to the app without the express prior written consent of Kosmique, and Kosmiquereserves the right, at any time, for any reason not prohibited by law, to deny permission to anyone to link a app from or to the app.</p>
 
<h2>Indemnity</h2>
<p>You release and agree to indemnify and hold Kosmique, its affiliates and their respective officers, directors, employees and agents harmless from any loss, liability, claim, or demand, including reasonable attorneys’ fees, made by any third party due to or arising out of (i) your use of the app in violation of the Terms of Use, (ii) any breach of your representations, warranties and covenants set forth in the Terms of Use, (iii) any material that you post using the app, and/or (iv) the use of the app by any other person using your account. We reserve the right to defend any such claim, and you agree to provide us with such reasonable cooperation and information as we may request.</p>
 
<h2>Waiver</h2>
<p>Kosmique’s failure at any time to require performance of any provision of the Terms of Use or to exercise any right provided for herein will not be deemed a waiver of such provision or such right. All waivers must be in writing. Unless the written waiver contains an express statement to the contrary, no waiver by Kosmiqueor any of its affiliates of any right under or term or provision of the Terms of Use will be deemed a waiver of any other right, term or provision of the Terms of Use at that time or a waiver of that or any other right, term or provision of the Terms of Use at any other time.</p>
 
<h2>Severability</h2>
</p>If any provision of the Terms of Use shall be deemed unlawful, void or for any reason unenforceable, then that provision shall be deemed severable from these terms and shall not affect the validity and enforceability of any remaining provisions.</p>
 
<h2>Governing Law, Jurisdiction and Venue</h2>
</p>The Terms of Use will be governed by and construed in accordance with the laws of the State of New York without regard to any conflict of law principles. You agree that any dispute or action at law or in equity arising out of or relating to the Terms of Use or your use of the app or its contents shall be commenced, and that venue properly lies, only in state or federal courts located in the borough of Manhattan, New York, New York, United States of America, and you consent and submit to the personal jurisdiction of such courts for the purposes of litigating any such action. However, we retain the right to bring an action in any jurisdiction where we believe that violation of the Terms of Use is taking place or originating.</p>
 
<h2>Entire Agreement</h2>
<p>The Terms of Use represent the entire agreement between Kosmique, on the one hand, and you, on the other hand, relating to the subject matter hereof.</p>
`

const TermsAndConditions = () => (
  <Layout>
    <Seo title="Terms and Conditions" />
		<BasicPage title="Terms and Conditions" content={content} />
  </Layout>
)

export default TermsAndConditions
